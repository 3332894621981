<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
import {GetTaskNumberDataGroupByClassify} from '../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from '../views/Dashboard/admin/components/mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '450px'
    },
    userid: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      chart: null,
      dataList: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
    GetTaskNumberDataGroupByClassify({userCode: this.userid}).then(res => {
      var data=res.data.response; 
      var myarr=[{ value: 0, name: '日常工作' },{ value: 0, name: '专项会议' },{ value: 0, name: '例会项目' },{ value: 0, name: '年度计划' }];
      if(data==null){return;}
      data.forEach(d => {
        myarr.forEach(m => {
          if(d.TaskClassify=="4" && m.name=="年度计划")
          {
               m.value=d.ClassifyCount;
          }
          else if(d.TaskClassify=="5" && m.name=="专项会议")
          {
            m.value=d.ClassifyCount;
          }
          else if(d.TaskClassify=="3" && m.name=="例会项目")
          {
            m.value=d.ClassifyCount;
          }
          else if(d.TaskClassify=="1" && m.name=="日常工作")
          {
            m.value=d.ClassifyCount;
          }
        });
      });
      this.dataList = myarr;
      this.initChart();
  
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
    
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '110',
          data: ['日常工作', '专项会议', '例会项目', '年度计划']
        },
        series: [
          {
            name: '任务',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '38%'],
            data : this.dataList,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      })
    }
  }
}
</script>
