<template>
    <section  style="background: #FFFFFF">
        <!--组织展示-->
        <el-col :span="4">
             <div class="orgbtndivtree">
                <el-divider>部门</el-divider>
                <el-cascader :props="props" v-model="cascadervalue" ref="department"
                 clearable size="mini" :show-all-levels="false" popper-class="depc" 
		         expand-trigger="hover" 
                 @change="cascaderchange"></el-cascader>
            </div>
            <div class="orgtree">
             <!--中间列表-->
                <el-table
                    :data="departusers"
                    highlight-current-row
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    empty-text="请选择部门"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    @current-change="selectDepartUserRow"
                    >
                    <el-table-column type="index" width="70px"></el-table-column>
                    <el-table-column label="姓名" prop="name" align="left"></el-table-column>
                    <!-- <el-table-column label="部门" prop="DepartmentName" align="center"></el-table-column> -->
                </el-table>
             <!-- 分页器 -->
            <el-pagination v-if="Pagination" align='center' small
                       :pager-count="5"  @current-change="handleCurrentChangeDepart" :current-page="DepartcurrentPage" :page-size="DepartpageSize" layout="prev, pager, next" :page-count="Departtotal" :total="Departtotaldata">
            </el-pagination>
         </div>
        </el-col>
        <!--工作数据展示-->
        <el-col :span="20">
            <div>
                <span style="color:#999" >选择查看年份：</span>
                <el-date-picker v-model="dateYear" type="year" @change="handleYearChange" :clearable="false" placeholder="选择查看年份"></el-date-picker>
                <el-divider>任务概览分析</el-divider>
                <!-- <el-form :inline="true" style="width: 100%;overflow-x: auto;white-space: nowrap;" size="mini">
                    <el-form-item label="年度计划:">
                        <el-select v-model = "YearPlanTask" @change="YearPlanchange" clearable>
                            <el-option v-for = "item in YearPlanTaskList" :key = "item.Id" :value = "`${item.YearPlanId}|${item.TaskId}|${item.WorkItem}`" :label = "item.WorkItem">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工作项目:">
                        <el-input v-model="workItem" placeholder="请输入" maxlength="10" show-word-limit clearable  style="width: 70%;"></el-input>
                    </el-form-item>
                    <el-form-item label="工作类型:" style="margin-left: -60px;">
                        <el-select
                            v-model="taskClassify"
                            style="width: 50%;"
                            clearable
                         >
                            <el-option
                                v-for="item in ClassifyModules"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="任务状态:" style="margin-left: -100px;">
                        <el-select
                            v-model="taskStatus"
                            style="width: 50%;"
                         >
                            <el-option
                                v-for="item in StatusModules"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item> 
                </el-form>
                <el-form :inline="true" style="margin-left: 100px" size="mini">      
                    <el-form-item label="任务进度:" style="margin-left: -100px;"> 
                        <el-input v-model="progressValue" placeholder="请输入" type="number" min= 0 max = 100></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期:" style="margin-left: -5px;">
                        <el-date-picker
                            v-model="madedate"
                            style="width: 70%;"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item style="margin-left: -100px;">
                        <el-button size="mini" type="goon" round icon="el-icon-search" @click="getManagerChecks">查询</el-button>
                    </el-form-item> 
               </el-form> -->
            </div>
            <div v-show="isdepartment">
                <!-- <RaddarCharttool :key="key" :departid = "departid"></RaddarCharttool> -->
                <span style="font-size: 12px;color: red;">各人员任务类型数量占比</span>
                <PeopleBarCharttool :key="key" :departid = "departid"></PeopleBarCharttool>
                <span style="font-size: 12px;color: red;">各人员任务执行进度占比</span>
                <BarCharttool :key="key" :departid = "departid"></BarCharttool>
                <span style="font-size: 12px;color: red;">各人员任务完成日期及数量占比</span>
                <PeopleTaskBarCharttool :key="key" :departid = "departid"></PeopleTaskBarCharttool>
            </div>
            <div v-show="isuser" style="width:1000px;height: 1000px;">
                <PieCharttool :key = "key" :userid = "userid"></PieCharttool>
            </div>
        </el-col>
    </section>
</template>
<script>
import util from '../../../util/date';
import RaddarCharttool from "../../components/RaddarCharttool"
import PieCharttool from "../../components/PieCharttool"
import BarCharttool from "../../components/BarCharttool"
import PeopleBarCharttool from "../../components/PeopleBarCharttool"
import PeopleTaskBarCharttool from "../../components/PeopleTaskBarCharttool"
import {GetChildrenList,QueryPageTasksByWhere,QueryChildTasksByParentId,GetUserTopDepartmentList,GetDepartmentPostUserList,GetYearPlanListByUserCode} from "../../api/oa"
export default {
     components: { RaddarCharttool,PieCharttool,BarCharttool,PeopleBarCharttool,PeopleTaskBarCharttool },
    data(){
        return{
            dateYear:new Date(),
            Pagination: true,
            currentPage: 1, // 当前页码
            total: null, // 总条数
            totaldata:null,
            pageSize: 15, // 每页的数据条数
            page: 1,
            DepartcurrentPage: 1, // 当前页码
            Departtotal: null, // 总条数
            Departtotaldata:null,
            DepartpageSize:  20, // 每页的数据条数
            Departpage: 1,
            departmentsearchdata: [],
            TypeIdModules: [],   //部门类型集合
            users: [],
            departusers: [],
            departmentList: [],
            DptProps: {
                label: 'Name',
                isLeaf: 'Leaf'
            },
            currentRow: null,
            DepartUserRow: null,
            workItem: null,
            taskClassify: 0,
            taskStatus: 0,
            progressValue: null,
            deptinput: null,
            madedate: null,
            cascadervalue: null,
            ClassifyModules:[
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "日常工作"
                },
                {
                    value: 2,
                    label: "跨部门协调"
                },
                {
                    value: 3,
                    label: "例会项目"
                },
                {
                    value: 4,
                    label: "年度计划"
                },
                {
                    value: 5,
                    label: "专项会议"
                },
            ],
            StatusModules: [
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "进行中"
                },
                // {
                //     value: 1,
                //     label: "已完成审核中"
                // },
                {
                    value: 2,
                    label: "已完成"
                },
                // {
                //     value: 3,
                //     label: "退回"
                // },
                // {
                //     value: 4,
                //     label: "新建任务待审核"
                // },
                // {
                //     value: 5,
                //     label: "取消任务待审核"
                // },
                // {
                //     value: 6,
                //     label: "新建任务退回" 
                // },
               
            ],
            checkVisible: false,
            checkForm: {            //查看表单初始化
                Id: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsNeedApproval: null
            },
            showAssign: false,
            createPerson: false,                    //创建人是否显示
            showyearplan: false,                    //年度计划查看界面显示控制
            showorganize: false,                     //跨部门协同查看界面显示控制
            showregularproject: false,               //例会来源查看界面控制
            showprofessionmeetong: false,            //专项会议查看界面控制
            CommonTaskClassifyText: null,
            pValue1: null,   //进度条数值
            istaskpro: false,
            tasks: [],
            options: [],
            checkdepartid: null,
            departuserid: null,
            props: {
                checkStrictly: true,
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level,value } = node;
                    if(node.level == 0){
                        var user = JSON.parse(window.localStorage.user);
                        var userid="";
                        if(user.name=='马玲' || user.name=='杨光')
                        {
                            userid='b57e4ab5-7df4-482b-a8f3-a36f012b963f';
                        }
                        else if(user.name=='刘丽丽' || user.name=='尹滕滕'|| user.name=='李欣晟' )//销售工程公司
                        {
                            userid='971e3fb0-f33a-45d1-bc28-a36f012b8df2';
                        }
                        else if (user.name == '田婷婷' || user.name == '张刚')
                        {
                            userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
                        }
                        else if (user.name == '赵娜')//人防公司
                        {
                            userid = 'f919ed9f-8660-44aa-96aa-a36f012b8cc7';
                        }
                        else if (user.name == '谢娇')//洁净空调
                        {
                            userid = 'ab753a23-e2ce-4c0d-96f3-a36f012b8892';
                        }
                        else if (user.name == '苏兴家')//能源
                        {
                            userid = '898dacfe-b63c-4b22-9646-a36f012b8d16';
                        }
                        else if (user.name == '路来亮')//通风二
                        {
                            userid = '679337b8-ccea-4449-9460-a36f012b8c27';
                        }
                        else if (user.name == '孙成伟')//安装
                        {
                            userid = 'db7992cd-88fa-4f1d-8557-a36f012b8cfa';
                        }
                        else if (user.name == '陈洪星')//通风一
                        {
                            userid = '4a745436-1600-4dc6-85da-a36f012b8665';
                        }
                        else if (user.name == '曹洪金')//通风配件
                        {
                            // userid = '8376b7af-d8fd-4d72-9545-9cf3de637bfe';
                            userid = 'd44af8f9-ced1-411d-8b0f-7f3ecbbaec9d';
                        }
                        else if (user.name == '韩朝晖')//环保一、活性炭
                        {
                            userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
                        }
                        else if (user.name == '付志浩')//环保科技
                        {
                            userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
                        }
                        else if (user.name == '邵小红')//风电
                        {
                            userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
                        }
                        else if (user.name == '陈卫卫')//管业
                        {
                            userid = 'ff176d0e-4213-493f-9be4-a36f012b942e';
                        }
                        else if (user.name == '李沛沛')//复材制品
                        {
                            userid = 'dcffef71-be23-4555-ab3d-a36f012b9559';
                        }
                        else if (user.name == '涂美铃')//复材科技
                        {
                            userid = '00861836-d4cf-4d23-b3f4-526fe507b59e';
                        }
                        else if (user.name == '白茹')//公共管理中心
                        {
                            userid = '418207c2-c8cd-403b-b719-a36f012b968e';
                        }
                        else if (user.name == '康佳')//公共管理中心
                        {
                            userid = '418207c2-c8cd-403b-b719-a36f012b968e';
                        }
                        else if (user.name == '贾欣慧')
                        {
                            userid = '418207c2-c8cd-403b-b719-a36f012b968e';
                        }
                        else{
                            userid=user.sub;
                        }
                        GetUserTopDepartmentList({userId: userid}).then(res => {
                            // console.log(res);
                                const nodes = res.data.data.map(x=>({
                                    value: x.deptId,
                                    label: x.deptName,
                                    leaf: !x.hasNode
                                }))
                                resolve(nodes)                         
                        });
                    }else{
                        // GetChildrenList({deptId: node.value}).then(res => {
                        //     console.log(res);
                        //     const nodes = res.data.data.dept.map(x=>({
                        //             value: x.id,
                        //             label: x.label,
                        //             leaf: !x.hasChildren
                        //         }))
                        //         resolve(nodes)
                        // })


                        GetChildrenList({deptId: node.value}).then(res => {
                            // console.log(res);
                            var mydata = res.data.data;
                            if(mydata.dept[0].children!=null)
                            {
                                const nodes = res.data.data.dept[0].children.map(x=>({
                                    value: x.id,
                                    label: x.label,
                                    leaf: !x.hasChildren
                                }))
                                // console.log(nodes);
                                resolve(nodes)   
                            }else{
                                resolve([]);
                            }
                        })
                    }
                    
                }
            },
            YearPlanTaskList: [],
            YearPlanTask: null,
            YearPlanTaskId: null,
            isdepartment: false,
            departid: [],
            key: 0,
            userid: '',
            isuser: false
        }
    },
    methods:{
        getYear:function()
        {
            let date = new Date(this.dateYear);
            //console.log("date:"+date);
            return date.getFullYear();
        },
        handleYearChange()
        {
            // console.log(this.dateYear);
            if(this.checkdepartid!=null)
            {
                this.getDepartUsers(this.checkdepartid);
            }
        },
        selectDepartUserRow(val){
            this.DepartUserRow = val;
            this.departuserid = val.id;
            this.YearPlanTaskId = null;
            // this.getManagerChecks();
            let para = {userCode: val.id};
            // GetYearPlanListByUserCode(para).then((res) => {
            //     this.YearPlanTaskList = res.data.response;
            // });
            // this.isuser = true;
            // this.isdepartment = false;
            // this.userid = val.UserId;
            // this.key += 1;
            console.log('selected');
        },
        YearPlanchange(val){
            this.YearPlanTask = val.split('|')[2];
            this.YearPlanTaskId = val.split('|')[1];
        },
        load(tree,treeNode,resolve){
            let para = {
                taskId: tree.Id,
            };
            QueryChildTasksByParentId(para).then((res) => {
                resolve(res.data.response)
            });
        },
        formatPlanComplateTime: function (row,colume) {
            return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime),'yyyy-MM-dd');
        },
        formatCreatedOn: function (row,colume) {
                return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn),'yyyy-MM-dd hh:mm');
            },
        formatCommonTaskClassifyText: function(row,colume){
                if(row.TaskClassify ==1){  
                    // return row.CommonTaskClassifyText;
                    return "日常工作";
                }else{
                    if(row.TaskClassify ==2)
                    {
                        return "跨部门协调";
                    }
                    else if(row.TaskClassify ==3)
                    {
                        return "例会项目";
                    }else if(row.TaskClassify ==4)
                    {
                        return "年度计划";
                    }else if(row.TaskClassify ==5){
                        return "专项会议";
                    }
                    else {
                        return "其他";
                    }
                }
            },
        formatSource: function (row,column) {
                return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
            },
        formatCreateTime: function(row,column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
            },
        formatTipCreateTime: function (row, column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime),'yyyy-MM-dd hh:mm');
            },
        //翻页处理
        handleCurrentChange(val) {
           this.page = val;
           this.getManagerChecks();
           this.page = 1;
      },
      getManagerChecks(){
           //时间转化
             let begintime = "";  //开始时间
             let endtime = "";    //结束时间
            if(this.madedate != null){
                switch (this.madedate.length) {
                    case 1:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    break;
                    case 2:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
                    break;
                    default:
                    break;
                }    
            }
            if(this.departuserid == null){
                 this.$message({
                    message: '请先选择想要查看任务的部门人员',
                    type: 'warning',
                    duration: 7000
                });
                return;
            }
            let para = {
                userCode: this.departuserid,
                workItem: this.workItem,
                taskClassify: this.taskClassify,
                taskStatus: this.taskStatus,
                begin: begintime,
                end: endtime,
                yearplanId: this.YearPlanTaskId,
                progressValue: this.progressValue,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTasksByWhere(para).then((res) => {
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
      },
        cascaderchange(node){

            // console.log(node);
            // 目的是选择之后将下拉界面收起
            this.$refs.department.toggleDropDownVisible();

            console.log("selected");
            this.Pagination = false;
            let pathvalue = this.$refs.department.getCheckedNodes()[0];
            this.checkdepartid = pathvalue.value;
            this.getDepartUsers(pathvalue.value);
            this.isdepartment = true;
            this.isuser = false;
            // this.key += 1;
            this.$refs.department.dropDownVisible = false;
        },
        handleCurrentChangeDepart(val){
           if(this.checkdepartid==null)
           {
               return;
           }
           this.Departpage = val;
           this.getDepartUsers(this.checkdepartid);
           this.Departpage = 1;
        //    this.key += 1;
        },
        //获取部门人员
        getDepartUsers(node){
            let para = {
                deptId: node,
                pageNum: this.Departpage,
                isAllNextDept:false,
                // isMainJob: true,
                // size: this.DepartpageSize
                pageSize: 1000
            }
            try
            {
                GetDepartmentPostUserList(para).then(res => {
                    var data=res.data.data.list;
                    let newdata=this.unique(data);

                    let arr=[];
                    newdata.forEach(element => {
                        if(element.workTypeName=='后勤')
                        {
                            arr.push(element);
                        }
                    });
                    // this.departusers = newdata;
                    this.departusers = arr;
                    this.Pagination = true;
                    // this.departid = newdata;
                    this.departid = arr;
                    // this.departid = res.data.response.data;
                    this.key += 1;
                    this.Departtotal = res.data.data.pages;
                    this.Departtotaldata = res.data.data.total;
                });
            }catch(err)
            {
                console.log('err')
            }

        },
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
        }
        

    },
    mounted(){
        
    }
}
</script>
<style scoped>
.orgtree {
  /* overflow-y: auto;
  overflow-x: scroll; */
  height: calc(100vh - 280px);
  width:200px;
  border: 0px solid blue;
  padding-top: 35px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
  
}
/deep/.el-table__body tr.current-row>td {
  background: #FABE64 !important;
}
/deep/input:disabled,/deep/textarea:disabled{
    opacity: 1;
    -webkit-text-fill-color: rgb(12, 12, 12);
}
.managercheckdiv {
 height: 600px;
}
#managercheckcard{
    height: 600px;
}
.tasksdiv {
    max-height: 400px;
    overflow: auto;
  padding-left: 2px;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
}
/deep/.el-button--goon {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
/deep/.el-button--goon:hover {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
</style>
<style>
.depc .el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0px;
    right: 0px;
}

/* span .el-radio__input { */
.depc .el-cascader-panel .el-radio__input {
    margin-top: 10px;
    margin-left: 8px;
}

/* div{
    background: red;;
} */

.depc .el-cascader-panel .el-cascader-node__postfix {
    top: 10px;
}
</style>